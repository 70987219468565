// src/components/briefing/BriefingPreview.js
import React from 'react';
import { Paper, Typography } from '@mui/material';

const BriefingPreview = ({ briefingData }) => {
    return (
        <Paper sx={{ padding: '16px', backgroundColor: '#f5f5f5', height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6">Brief preview</Typography>
            <Typography variant="body1"><strong>Strategy:</strong> {briefingData.strategy}</Typography>
            <Typography variant="body1"><strong>Market Readiness:</strong> {briefingData.marketReadiness.join(', ')}</Typography>
            <Typography variant="body1"><strong>Focus:</strong> {briefingData.focus}</Typography>
            <Typography variant="body1"><strong>Keywords:</strong> {briefingData.keywords}</Typography>
            <Typography variant="body1"><strong>Negative Keywords:</strong> {briefingData.negativeKeywords}</Typography>
            <Typography variant="body1"><strong>Relevant Companies:</strong> {briefingData.relevantCompanies}</Typography>
            <Typography variant="body1"><strong>Irrelevant Solutions:</strong> {briefingData.irrelevantSolutions}</Typography>
            <Typography variant="body1"><strong>Problem/Challenge:</strong> {briefingData.problemChallenge}</Typography>
            <Typography variant="body1"><strong>Application Areas:</strong> {briefingData.applicationAreas}</Typography>
            <Typography variant="body1"><strong>Technologies:</strong> {briefingData.technologies}</Typography>
        </Paper>
    );
};

export default BriefingPreview;
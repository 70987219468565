import React from 'react';
import { Avatar, Box } from '@mui/material';
import { logo } from '../../utils/imagePaths';

const LogoAvatar = () => {
    return (
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Box component="img" src={logo} alt="Logo" sx={{ width: '100%', height: '100%' }} />
        </Avatar>
    );
};

export default LogoAvatar;

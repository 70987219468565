// src/components/public/Register.js
import React, { useState } from 'react';
import { Typography, Box, Grid, Paper, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import BackgroundContainer from './BackgroundContainer';
import LogoAvatar from './LogoAvatar';
import RegisterForm from './RegisterForm';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { register } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(null);
        setError(null);
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        try {
            await register(email, password);
            setMessage('Registration successful. Please check your email for verification.');
            navigate('/login');
        } catch (err) {
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <BackgroundContainer />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <LogoAvatar />
                    <Typography component="h1" variant="h5">Register</Typography>
                    <RegisterForm
                        handleSubmit={handleSubmit}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        error={error}
                        message={message}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            Already registered? <Link onClick={() => navigate('/login')} sx={{ cursor: 'pointer' }}>Login here</Link>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Register;
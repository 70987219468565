// src/components/public/LoginForm.js
import React from 'react';
import { TextField, Button, Box, Typography, Link } from '@mui/material';

const LoginForm = ({ handleSubmit, email, setEmail, password, setPassword, error, message, navigateToResetPassword, navigateToRegister }) => {
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, borderRadius: 3 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)} 
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            
            />
            {error && <Typography color="error">{error}</Typography>}
            {message && <Typography color="primary">{message}</Typography>}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: '16px', padding: 2 }}
            >
                <Typography component="h1" variant="h6">Login</Typography>
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link onClick={navigateToResetPassword} sx={{ cursor: 'pointer' }}>
                <Typography component="body2" variant="body2" color="primary.light">Forgot password?</Typography>
                </Link>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography component="body2" variant="body2" color="primary.dark">Don't have an account?&ensp;</Typography>
                    <Link onClick={navigateToRegister} sx={{ cursor: 'pointer' }}>
                    <Typography component="body2" variant="body2" color="primary.light">Sign Up</Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default LoginForm;

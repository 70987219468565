import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import CompanyItem from './CompanyItem';
import axiosInstance from "../../../axiosInstance";

const CompanySearchResults = () => {
    const location = useLocation();
    const { prompt } = location.state;
    const [results, setResults] = useState(null);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/proxy/api:Y3T-Nl6Y/getCompaniesByPromt`, {
                    params: { prompt }
                });
                setResults(response.data);
            } catch (error) {
                console.error('Error fetching companies', error);
            }
        };

        fetchResults();
    }, [prompt]);

    if (!results) return <Typography>Loading...</Typography>;

    const { companies } = results;

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>{prompt}</Typography>
            {companies.map(company => (
                <Link key={company.id} to={`/company/${company.Slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Box sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                        <CompanyItem company={company} />
                    </Box>
                </Link>
            ))}
        </Box>
    );
};

export default CompanySearchResults;

import React from 'react';
import { Box, Avatar, Typography, Chip, Button } from '@mui/material';
import { Link } from 'react-router-dom';
// Import the PNG images from the correct path
import MoonshotIcon from '../../../images/Moonshot_Icon.png';
import DTXIcon from '../../../images/DTX_Icon.png';

const SimilarCompanyCard = ({ similar }) => {
    const progressValue = similar.Similarity_value;

    return (
        <Box sx={{
            width: 340,
            height: 237,
            borderRadius: 2,
            backgroundColor: '#2E4E6F', // Updated background color
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: '#fff', // Ensure text is white
            overflow: 'hidden'
        }}>
            <Box sx={{ padding: 2 }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Avatar src={similar.Logo_Image?.url || ''} alt={similar.Company_name} sx={{ width: 60, height: 60, mr: 2, borderRadius: 2, bgcolor: '#B0BEC5' }} />
                    <Box>
                        <Typography variant="h6">{similar.Company_name}</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            {similar.Is_Moonshot && <Box component="img" src={MoonshotIcon} alt="Moonshot" sx={{ width: 24, height: 24, mr: 1 }} />}
                            {similar.Is_Dtx && <Box component="img" src={DTXIcon} alt="DTX" sx={{ width: 24, height: 24 }} />}
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexWrap="wrap" mb={2}>
                    {similar._company_diseasecategories_of_company_basi?.map(category => (
                        <Chip key={category.id} label={category.Category_name} sx={{
                            backgroundColor: '#375A7F',
                            color: '#fff',
                            borderRadius: '4px',
                            mr: 1,
                            mb: 1
                        }} />
                    ))}
                </Box>
            </Box>
            <Box sx={{
                backgroundColor: '#112A43',
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px', // Consistent padding
                width: '100%',
                boxSizing: 'border-box', // Include padding in width calculation
            }}>
                <Box sx={{
                    width: '134px',
                    height: '32px',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(195, 228, 127, 0.7)',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0, // Ensure it doesn't shrink
                }}>
                    <Box sx={{
                        width: `${progressValue}%`,
                        height: '100%',
                        backgroundColor: '#C3E47F',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        borderRadius: '8px',
                    }}></Box>
                    <Typography sx={{
                        position: 'relative',
                        zIndex: 1,
                        width: '100%',
                        textAlign: 'center',
                        color: '#112A43',
                        fontWeight: 'bold',
                        fontSize: '14px', // Adjust font size if needed
                    }}>{`${progressValue}% similar`}</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} /> {/* Spacer to push the button to the right */}
                <Button component={Link} to={`/company/${similar.Slug}`} variant="text" size="small" sx={{
                    color: '#fff',
                    textAlign: 'right',
                    minWidth: '100px',
                    flexShrink: 0, // Ensure it doesn't shrink
                }}>
                    View details
                </Button>
            </Box>
        </Box>
    );
};

export default SimilarCompanyCard;

import React from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter,
    Box,
    Pagination,
    TableCell,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CompanyItem from './CompanyItem';

const CompanyList = ({ companies, totalItems, page, setPage }) => {
    const navigate = useNavigate();

    const handleChangePage = (event, value) => {
        setPage(value);
        navigate(`?page=${value}`);
    };

    const totalPages = Math.ceil(totalItems / 25);

    return (
        <TableContainer component={Paper} sx={{
            backgroundColor: '#ECEFF3',
            borderRadius: '10px',
            mt: '10px',
            mb: '10px',
        }}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Beschreibung</TableCell>
                        <TableCell>Erstellt am</TableCell>
                        <TableCell>Kategorie</TableCell>
                        <TableCell>Subkategorie</TableCell>
                        <TableCell>Ähnliche Firmen</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company, index) => (
                        <TableRow key={company.id} sx={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            marginBottom: '5px',
                            '&:last-child': {
                                marginBottom: 0,
                            },
                        }}>
                            <CompanyItem company={company} />
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={7}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handleChangePage}
                                    color="primary"
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default CompanyList;

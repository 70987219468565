// src/components/briefing/BriefingStepNavigator.js
import React from 'react';
import { Box, Button, Stepper, Step, StepLabel } from '@mui/material';

const BriefingStepNavigator = ({ steps, activeStep, setActiveStep }) => {
    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Stepper activeStep={activeStep} sx={{ marginBottom: '16px' }}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: '16px' }}>
                {steps[activeStep]?.component}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleBack} disabled={activeStep === 0}>
                    Back
                </Button>
                <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </Box>
        </Box>
    );
};

export default BriefingStepNavigator;
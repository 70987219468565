import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Avatar, Typography } from '@mui/material';
import { logo } from '../../../utils/imagePaths';

const Step3Chat = ({ handleBackStep, handleFinish }) => {
    const [messages, setMessages] = useState([
        {
            text: `Let's create a new brief!
            We'll need information on what you want to search.
            The more complete the information, the more accurate the results.
            Please answer the following questions so we know what you want to search for?`,
            sender: 'AI'
        }
    ]);
    const [input, setInput] = useState('');
    const ws = useRef(null);

    useEffect(() => {
        ws.current = new WebSocket('wss://ws.theroxfox.com/ws/briefing');

        ws.current.onopen = () => {
            console.log('WebSocket Connected');
        };

        ws.current.onmessage = (event) => {
            try {
                const parsedMessage = JSON.parse(event.data);
                setMessages((prevMessages) => [...prevMessages, parsedMessage]);
            } catch (e) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: event.data, sender: 'AI' }
                ]);
            }
        };

        ws.current.onclose = () => {
            console.log('WebSocket Disconnected');
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket Error', error);
        };

        return () => {
            if (ws.current) ws.current.close();
        };
    }, []);

    const handleSendMessage = () => {
        if (input.trim()) {
            const userMessage = {
                text: input,
                sender: 'user',
            };
            setMessages((prevMessages) => [...prevMessages, userMessage]);

            ws.current.send(JSON.stringify({ text: input }));

            setInput('');
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh', border: '1px solid #ccc', borderRadius: '8px' }}>
            <Typography variant="h6" sx={{ padding: '8px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ccc' }}>
                AI Assistant
            </Typography>
            <List sx={{ flexGrow: 1, overflowY: 'auto', padding: '8px' }}>
                {messages.map((message, index) => (
                    <ListItem key={index} sx={{ alignItems: 'flex-start' }}>
                        {message.sender === 'AI' && (
                            <Avatar alt="AI Logo" src={logo} sx={{ marginRight: '8px' }} />
                        )}
                        <ListItemText
                            primary={<pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', margin: 0 }}>{message.text}</pre>}
                            secondary={message.sender === 'user' ? 'You' : 'AI'}
                        />
                    </ListItem>
                ))}
            </List>
            <Box sx={{ display: 'flex', padding: '8px', borderTop: '1px solid #ccc' }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage();
                        }
                    }}
                />
                <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ marginLeft: '8px' }}>
                    Send
                </Button>
            </Box>
        </Box>
    );
};

export default Step3Chat;
// src/components/public/ResetPasswordRequest.js
import React, { useState } from 'react';
import { Typography, Box, Grid, Paper, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebaseConfig'; // Firebase auth importieren
import BackgroundContainer from './BackgroundContainer';
import LogoAvatar from './LogoAvatar';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(null);
        setError(null);
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Reset link sent. Please check your email.');
        } catch (err) {
            setError('Failed to send reset link. Please try again.');
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <BackgroundContainer />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <LogoAvatar />
                    <Typography component="h1" variant="h5">Reset Password</Typography>
                    <ResetPasswordForm
                        handleSubmit={handleSubmit}
                        email={email}
                        setEmail={setEmail}
                        error={error}
                        message={message}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            Remembered your password? <Link onClick={() => navigate('/login')} sx={{ cursor: 'pointer' }}>Login here</Link>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ResetPasswordRequest;
import React from 'react';
import { Grid, Box } from '@mui/material';
import { backgroundImage } from '../../utils/imagePaths';

const BackgroundContainer = () => {
    return (
        <Grid item xs={false} sm={4} md={7} sx={{
            backgroundColor: '#011529',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box
                component="img"
                src={backgroundImage}
                alt="Background"
                sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                }}
            />
        </Grid>
    );
};

export default BackgroundContainer;

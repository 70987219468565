// src/components/briefing/Step2Details.js
import React from 'react';
import { Box, TextField } from '@mui/material';

const Step2Details = ({ briefingData, setBriefingData }) => {
    return (
        <Box>
            <TextField
                fullWidth
                label="Keywords"
                value={briefingData.keywords || ''}
                onChange={(e) => setBriefingData({ ...briefingData, keywords: e.target.value })}
                sx={{ marginBottom: '16px' }}
            />
            <TextField
                fullWidth
                label="Negative Keywords"
                value={briefingData.negativeKeywords || ''}
                onChange={(e) => setBriefingData({ ...briefingData, negativeKeywords: e.target.value })}
                sx={{ marginBottom: '16px' }}
            />
            <TextField
                fullWidth
                label="Relevant Companies"
                value={briefingData.relevantCompanies || ''}
                onChange={(e) => setBriefingData({ ...briefingData, relevantCompanies: e.target.value })}
                sx={{ marginBottom: '16px' }}
            />
            <TextField
                fullWidth
                label="Irrelevant Solutions"
                value={briefingData.irrelevantSolutions || ''}
                onChange={(e) => setBriefingData({ ...briefingData, irrelevantSolutions: e.target.value })}
                sx={{ marginBottom: '16px' }}
            />
        </Box>
    );
};

export default Step2Details;
import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, TextField, Typography, IconButton, Paper, List, ListItem, ListItemText } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [retryMessage, setRetryMessage] = useState(null);
    const messagesEndRef = useRef(null);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // Always call useEffect, but it will only run when messages change

    const handleSendMessage = async (message) => {
        if (!message) return;

        const userMessage = { text: message, sender: 'user' };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        setRetryMessage(null); // Reset retry message

        try {
            const response = await axios.get('https://api.theroxfox.com/company-finder-rag', {
                params: { question: message }
            });

            const botMessage = {
                text: response.data.answer,
                sender: 'bot',
                sources: response.data.sources,
                companyName: response.data['c.name'],
                bubbleUrl: response.data['c.bubbleUrl'],
                contextText: response.data['n.text']
            };

            setMessages(prevMessages => [...prevMessages, userMessage, botMessage]);
        } catch (error) {
            const errorMessage = { text: 'Fehler beim Abrufen der Antwort. Bitte versuchen Sie es später erneut.', sender: 'bot' };
            setMessages(prevMessages => [...prevMessages, userMessage, errorMessage]);
            setRetryMessage(message); // Store the message for retry
        }

        setInput('');
    };

    const handleRetry = () => {
        if (retryMessage) {
            handleSendMessage(retryMessage);
        }
    };


    return (
        <Box sx={{ position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}>
            {isOpen ? (
                <Paper sx={{ width: 320, height: 400, p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="h6">Chatbot</Typography>
                        <IconButton size="small" onClick={toggleChat}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                        {messages.map((message, index) => (
                            <ListItem key={index} sx={{ textAlign: message.sender === 'user' ? 'right' : 'left' }}>
                                <ListItemText
                                    primary={message.text}
                                    secondary={message.sender === 'bot' && (
                                        <>
                                            {message.contextText && <Typography variant="body2">{message.contextText}</Typography>}
                                            {message.companyName && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    href={message.bubbleUrl}
                                                    target="_blank"
                                                    sx={{ mt: 1 }}
                                                >
                                                    {message.companyName}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                />
                            </ListItem>
                        ))}
                        {retryMessage && (
                            <ListItem sx={{ textAlign: 'left' }}>
                                <Button variant="contained" color="secondary" onClick={handleRetry}>
                                    Erneut versuchen
                                </Button>
                            </ListItem>
                        )}
                        <div ref={messagesEndRef} />
                    </List>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSendMessage(input);
                                }
                            }}
                        />
                        <Button variant="contained" color="primary" onClick={() => handleSendMessage(input)} sx={{ ml: 1 }}>
                            Senden
                        </Button>
                    </Box>
                </Paper>
            ) : (
                <IconButton color="primary" onClick={toggleChat}>
                    <ChatIcon sx={{ fontSize: 40 }} />
                </IconButton>
            )}
        </Box>
    );
};

export default Chatbot;
import React, { useState, useEffect, useRef } from 'react';
import { TextField, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const navigate = useNavigate();
    const suggestionsRef = useRef([]);
    const inputRef = useRef(null);

    useEffect(() => {
        if (query.length >= 3) {
            const fetchSuggestions = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/proxy/api:Y3T-Nl6Y/search_all_companybasic`, {
                        params: { search_query: query },
                    });
                    setSuggestions(response.data);
                    setShowSuggestions(true);
                } catch (error) {
                    console.error('Error fetching suggestions', error);
                }
            };
            fetchSuggestions();
        } else {
            setShowSuggestions(false);
        }
    }, [query]);

    const handleInputChange = (event) => {
        setQuery(event.target.value);
        setHighlightedIndex(-1);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
        } else if (event.key === 'Enter') {
            if (highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
                handleSuggestionSelect(suggestions[highlightedIndex]);
            }
        } else if (event.key === 'Escape') {
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        handleSuggestionSelect(suggestion);
    };

    const handleSuggestionSelect = (suggestion) => {
        setShowSuggestions(false);
        navigate(`/company/${suggestion.Slug}`);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        if (highlightedIndex >= 0 && highlightedIndex < suggestions.length && suggestionsRef.current[highlightedIndex]) {
            suggestionsRef.current[highlightedIndex].scrollIntoView({
                block: 'nearest',
                behavior: 'smooth',
            });
        }
    }, [highlightedIndex, suggestions]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Box sx={{ position: 'relative', width: '100%' }} ref={inputRef}>
            <TextField
                fullWidth
                variant="outlined"
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Search for companies"
                autoComplete="off"
            />
            {showSuggestions && (
                <List sx={{ position: 'absolute', width: '100%', bgcolor: 'background.paper', zIndex: 1, maxHeight: 200, overflowY: 'auto' }}>
                    {suggestions.map((suggestion, index) => (
                        <ListItem
                            button
                            key={suggestion.Slug}
                            onClick={() => handleSuggestionClick(suggestion)}
                            selected={index === highlightedIndex}
                            ref={(el) => (suggestionsRef.current[index] = el)}
                        >
                            <ListItemAvatar>
                                <Avatar src={suggestion.Logo_Image?.url || ''} alt={suggestion.Company_name} />
                            </ListItemAvatar>
                            <ListItemText primary={suggestion.Company_name} />
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default SearchBar;

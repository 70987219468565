import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Typography } from '@mui/material';

const Step1StructuredInput = ({ briefingData, setBriefingData, handleNextStep }) => {
    const handleChange = (e) => {
        setBriefingData({
            ...briefingData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckboxChange = (e) => {
        setBriefingData({
            ...briefingData,
            [e.target.name]: e.target.checked,
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Step 1: Structured Input</Typography>
            <FormControl fullWidth sx={{ marginTop: '16px' }}>
                <InputLabel id="strategy-label">Strategy</InputLabel>
                <Select
                    labelId="strategy-label"
                    id="strategy"
                    name="strategy"
                    value={briefingData.strategy || ''}
                    onChange={handleChange}
                >
                    <MenuItem value="Strategy 1">Strategy 1</MenuItem>
                    <MenuItem value="Strategy 2">Strategy 2</MenuItem>
                    <MenuItem value="Strategy 3">Strategy 3</MenuItem>
                </Select>
            </FormControl>
            <Typography sx={{ marginTop: '16px' }}>What type of solutions are interesting for you?</Typography>
            <FormControlLabel
                control={<Checkbox checked={briefingData.ideaStage || false} onChange={handleCheckboxChange} name="ideaStage" />}
                label="Idea Stage"
            />
            <FormControlLabel
                control={<Checkbox checked={briefingData.prototypeStage || false} onChange={handleCheckboxChange} name="prototypeStage" />}
                label="Prototype Stage"
            />
            <FormControlLabel
                control={<Checkbox checked={briefingData.matureSolutions || false} onChange={handleCheckboxChange} name="matureSolutions" />}
                label="Mature Solutions"
            />
            <Typography sx={{ marginTop: '16px' }}>Focus:</Typography>
            <FormControlLabel
                control={<Checkbox checked={briefingData.regional || false} onChange={handleCheckboxChange} name="regional" />}
                label="Regional"
            />
            <FormControlLabel
                control={<Checkbox checked={briefingData.global || false} onChange={handleCheckboxChange} name="global" />}
                label="Global"
            />
        </Box>
    );
};

export default Step1StructuredInput;
// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCTvvkYXAMH-raGIL7pJ3XmAoudezY2LQg",
    authDomain: "moonshot-finder.firebaseapp.com",
    projectId: "moonshot-finder",
    storageBucket: "moonshot-finder.appspot.com",
    messagingSenderId: "50123189704",
    appId: "1:50123189704:web:3b363590d073753366a4fd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
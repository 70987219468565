import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AlertProvider = ({ children }) => {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            autoHideDuration={3000}
        >
            {children}
        </SnackbarProvider>
    );
};

export const useAlert = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showAlert = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
            action: (key) => (
                <IconButton onClick={() => closeSnackbar(key)} color="inherit">
                    <CloseIcon />
                </IconButton>
            ),
        });
    };

    return showAlert;
};

export default AlertProvider;
import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Filter from './Filter';
import Sort from './Sort';
import AddCompany from './AddCompany';

const Sidebar = ({ totalItems, dtx, moonshot, category, sort, onApply, onSortChange }) => {
    useEffect(() => {
        const selectedSortOption = {
            sort: 'created_at',
            sort_order: 'desc',
            sort_type: 'number'
        };
        onSortChange(selectedSortOption);
    }, [onSortChange]);

    return (
        <Box component="aside"
             className="sidebar"
             sx={{
                 width: '100%',
                 p: 2,
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 backgroundColor: '#ECEFF3',
                 borderRadius: '10px',
                 mt: '10px',
                 mb: '10px',
             }}>

            {/* Left side */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ color: '#386FA6', mr: 2 }}>{totalItems} Firmen gefunden</Typography>
            </Box>

            {/* Right side */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {/* Filters */}
                <Filter dtx={dtx} moonshot={moonshot} category={category} onApply={onApply} />

                {/* Sort */}
                <Sort sort={sort} onSortChange={onSortChange} />

                {/* Add Company */}
                <AddCompany />
            </Box>
        </Box>
    );
};

export default Sidebar;

// src/components/SearchPrompt.js
import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search.js';

const SearchPrompt = () => {
    const [prompt, setPrompt] = useState('Gib mir Unternehmen an, die sich mit digitaler Pathologie beschäftigen oder an folgenden Technologien arbeiten: Whole Slide Imaging (WSI), computergestützte Diagnose (CADx).');
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const handleSearch = () => {
        navigate('/search-results', { state: { prompt } });
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
            <TextField
                fullWidth
                variant="outlined"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                multiline
                rows={isFocused ? 4 : 1} // Expand rows on focus
                onFocus={() => setIsFocused(true)} // Set focus state to true
                onBlur={() => setIsFocused(false)} // Set focus state to false
                placeholder="Enter your search prompt..."
                InputProps={{
                    style: { fontSize: '0.8rem', color: 'white' }, // Smaller font size and white color for text
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent', // Remove border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent', // Remove border color on hover
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent', // Remove border color on focus
                        },
                    },
                    padding: '4px 8px', // Adjust padding to reduce height
                }}
                InputLabelProps={{
                    style: { color: '#C5E1FC' } // Color for placeholder text
                }}
                sx={{
                    bgcolor: 'primary.dark',
                    borderRadius: 1,
                    transition: 'all 0.3s ease', // Smooth transition
                    maxWidth: isFocused ? '600px' : '300px', // Expand width on focus
                    height: isFocused ? 'auto' : '40px' // Adjust height
                }}
            />
            <Button onClick={handleSearch} variant="contained" sx={{ ml: 2, bgcolor: 'primary.dark', color: 'white', boxShadow: 'none' }}>
            <SearchIcon />
            </Button>
        </Box>
    );
};

export default SearchPrompt;

// src/components/briefing/BriefingEditor.js
import React from 'react';
import BriefingStepNavigator from './BriefingStepNavigator';
import Step1StructuredInput from './Step1StructuredInput';
import Step2Details from './Step2Details';
import Step3Chat from './Step3Chat';

const BriefingEditor = ({ briefingData, setBriefingData, activeStep, setActiveStep }) => {
    const steps = [
        {
            label: 'Structured Input',
            component: <Step1StructuredInput briefingData={briefingData} setBriefingData={setBriefingData} />,
        },
        {
            label: 'Provide More Details',
            component: <Step2Details briefingData={briefingData} setBriefingData={setBriefingData} />,
        },
        {
            label: 'Final Review & Chat',
            component: <Step3Chat briefingData={briefingData} setBriefingData={setBriefingData} />,
        },
    ];

    return (
        <BriefingStepNavigator
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
        />
    );
};

export default BriefingEditor;
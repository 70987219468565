// src/components/private/Header.js
import React from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Button, useMediaQuery, useTheme, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import SearchPrompt from './SearchPrompt';
import logo from '../../images/rox-fox.png';
import { useAuth } from '../../AuthContext';

const Header = ({ setSearchResults }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
    const [scoutingAnchorEl, setScoutingAnchorEl] = React.useState(null); // State für das Scouting-Dropdown
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { logout, currentUser } = useAuth();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenu = (event) => {
        setMobileMenuAnchorEl(event.currentTarget);
    };

    const handleScoutingMenu = (event) => {
        setScoutingAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMobileMenuAnchorEl(null);
        setScoutingAnchorEl(null); // Schließe das Scouting-Dropdown
    };

    const handleProfile = () => {
        handleClose();
        navigate('/profile');
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <AppBar position="static" style={{ backgroundColor: 'primary.main', margin: 0 }}>
            <Toolbar>
                {isMobile && (
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMobileMenu}>
                        <MenuIcon />
                    </IconButton>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Button color="inherit" onClick={() => navigate('/')} style={{ padding: 0, minWidth: 'auto' }}>
                        <img src={logo} alt="Logo" style={{ height: 40 }} />
                    </Button>
                    {!isMobile && (
                        <>
                            <Button
                                color="inherit"
                                onClick={handleScoutingMenu}
                                style={{ color: 'white', marginLeft: '16px' }}
                            >
                                Scouting
                            </Button>
                            <Menu
                                anchorEl={scoutingAnchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(scoutingAnchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => { navigate('/companies'); handleClose(); }}>Companies</MenuItem>
                                <MenuItem onClick={() => { navigate('/briefings'); handleClose(); }}>Briefing</MenuItem>
                            </Menu>
                        </>
                    )}
                    <Box sx={{ marginLeft: '16px', flexGrow: 1 }}>
                        <SearchPrompt setSearchResults={setSearchResults} />
                    </Box>
                </Box>
                {currentUser && (
                    <div>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleProfile}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
            {isMobile && (
                <Menu
                    anchorEl={mobileMenuAnchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(mobileMenuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => { navigate('/companies'); handleClose(); }}>Companies</MenuItem>
                    <MenuItem onClick={() => { navigate('/briefings'); handleClose(); }}>Briefing</MenuItem>
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                </Menu>
            )}
        </AppBar>
    );
};

export default Header;
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import CompanyList from './CompanyList';
import Sidebar from './Sidebar';
import axiosInstance from '../../../axiosInstance';
import SearchBar from "./SearchBar";

const CompanyOverviewLayout = () => {
    const [totalItems, setTotalItems] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [filters, setFilters] = useState({ dtx: false, moonshot: false, category: '' });
    const [sortCriteria, setSortCriteria] = useState({
        sort: 'created_at',
        sort_order: 'desc',
        sort_type: 'number'
    });
    const [page, setPage] = useState(1);

    const fetchCompanies = useCallback(async () => {
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_API_URL}/proxy/api:Y3T-Nl6Y/multiparameter_searchcompanies`,
                {
                    page,
                    limit: 25,
                    ...filters,
                    disease_id: filters.category,
                    sort_by: sortCriteria.sort,
                    sort_order: sortCriteria.sort_order,
                    sort_type: sortCriteria.sort_type
                }
            );
            setCompanies(response.data.companies);
            setTotalItems(response.data.itemstotal);
        } catch (error) {
            console.error('Error fetching companies', error);
        }
    }, [filters, sortCriteria, page]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    const handleApplyFilters = useCallback((dtx, moonshot, category) => {
        setFilters({ dtx, moonshot, category });
        setPage(1); // Reset to the first page when filters change
    }, []);

    const handleSortChange = useCallback((sort) => {
        setSortCriteria(sort);
        setPage(1); // Reset to the first page when sort criteria change
    }, []);

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SearchBar></SearchBar>

                    <Sidebar
                        totalItems={totalItems}
                        dtx={filters.dtx}
                        moonshot={filters.moonshot}
                        category={filters.category}
                        sort={sortCriteria}
                        onApply={handleApplyFilters}
                        onSortChange={handleSortChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CompanyList
                        companies={companies}
                        totalItems={totalItems}
                        page={page}
                        setPage={setPage}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CompanyOverviewLayout;

// src/components/public/ResetPasswordForm.js
import React from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const ResetPasswordForm = ({ handleSubmit, email, setEmail, error, message }) => {
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            {error && <Typography color="error">{error}</Typography>}
            {message && <Typography color="primary">{message}</Typography>}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Request Reset Link
            </Button>
        </Box>
    );
};

export default ResetPasswordForm;

// src/components/public/RegisterForm.js
import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const RegisterForm = ({ handleSubmit, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, error, message }) => {
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        number: false,
        lowercase: false,
        uppercase: false,
        specialChar: false
    });

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);

        const criteria = {
            length: newPassword.length >= 8,
            number: /\d/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            uppercase: /[A-Z]/.test(newPassword),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
        };
        setPasswordCriteria(criteria);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const isPasswordValid = Object.values(passwordCriteria).every(value => value);

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
            />
            <Box sx={{ mb: 2 }}>
                <Typography>Passwortanforderungen:</Typography>
                <Typography color={passwordCriteria.length ? 'success.main' : 'error.main'}>• Mindestens 8 Zeichen</Typography>
                <Typography color={passwordCriteria.number ? 'success.main' : 'error.main'}>• Mindestens 1 Zahl</Typography>
                <Typography color={passwordCriteria.lowercase ? 'success.main' : 'error.main'}>• Mindestens 1 Kleinbuchstabe</Typography>
                <Typography color={passwordCriteria.uppercase ? 'success.main' : 'error.main'}>• Mindestens 1 Großbuchstabe</Typography>
                <Typography color={passwordCriteria.specialChar ? 'success.main' : 'error.main'}>• Mindestens 1 Sonderzeichen</Typography>
            </Box>
            {error && <Typography color="error">{error}</Typography>}
            {message && <Typography color="primary">{message}</Typography>}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!email || !password || password !== confirmPassword || !isPasswordValid}
            >
                Register
            </Button>
        </Box>
    );
};

export default RegisterForm;

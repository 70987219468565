import React from 'react';
import { Box, FormControlLabel, Checkbox, Select, MenuItem, Button } from '@mui/material';
import { useAlert } from '../../Alert';

const Filter = ({ dtx, moonshot, category, onApply }) => {
    const showAlert = useAlert();

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        onApply(name === 'dtx' ? checked : dtx, name === 'moonshot' ? checked : moonshot, category);
    };

    const handleSelectChange = (event) => {
        onApply(dtx, moonshot, event.target.value);
    };

    const handleClear = () => {
        onApply(false, false, '');
        showAlert('Filters cleared', 'info');
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControlLabel
                control={<Checkbox checked={dtx} onChange={handleCheckboxChange} name="dtx" />}
                label="DTX"
            />
            <FormControlLabel
                control={<Checkbox checked={moonshot} onChange={handleCheckboxChange} name="moonshot" />}
                label="Moonshot"
            />
            <Select
                variant="standard"
                value={category}
                onChange={handleSelectChange}
                size="small"
                displayEmpty
                sx={{ minWidth: 200 }}
            >
                <MenuItem value="">
                    <em>Choose category</em>
                </MenuItem>
                <MenuItem value="8">Cardiovascular Disease</MenuItem>
            </Select>
            <Button variant="outlined" color="primary" size="small" onClick={handleClear}>
                Clear
            </Button>
        </Box>
    );
};

export default Filter;

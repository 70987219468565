// src/components/public/Login.js
import React, { useState } from 'react';
import { Typography, Box, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import LoginForm from './LoginForm';
import BackgroundContainer from './BackgroundContainer';
import LogoAvatar from './LogoAvatar';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setMessage(null);
            await login(email, password);
            navigate('/companies');
        } catch (err) {
            setMessage('Login failed. Please try again.');
        }
    };

    const navigateToResetPassword = () => {
        navigate('/reset-password');
    };

    const navigateToRegister = () => {
        navigate('/register');
    };

    return (
        <Grid container component="main" sx={{ height: '98vh', margin: '4' }}>
            <BackgroundContainer />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={4} square>
                <Box sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'primary.dark',
                }}>
                    <LogoAvatar />
                    <Typography component="h1" variant="h5">Login</Typography>
                    <LoginForm
                        handleSubmit={handleSubmit}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        error={null}
                        message={message}
                        navigateToResetPassword={navigateToResetPassword}
                        navigateToRegister={navigateToRegister}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Login;
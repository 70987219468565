// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebaseConfig';
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    localStorage.setItem('authToken', token);
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    setAuthToken(token);
                    setCurrentUser(user);
                    setIsAuthenticated(true);
                    setLoading(false);
                });
            } else {
                localStorage.removeItem('authToken');
                delete axiosInstance.defaults.headers.common['Authorization'];
                setAuthToken(null);
                setCurrentUser(null);
                setIsAuthenticated(false);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const login = async (email, password) => {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const token = await userCredential.user.getIdToken();
        localStorage.setItem('authToken', token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setAuthToken(token);
        setCurrentUser(userCredential.user);
        setIsAuthenticated(true);
        setLoading(false);
        navigate('/');
    };

    const register = async (email, password) => {
        await createUserWithEmailAndPassword(auth, email, password);
    };

    const resetPassword = async (email) => {
        await sendPasswordResetEmail(auth, email);
    };

    const logout = async () => {
        await signOut(auth);
        localStorage.removeItem('authToken');
        delete axiosInstance.defaults.headers.common['Authorization'];
        setAuthToken(null);
        setCurrentUser(null);
        setIsAuthenticated(false);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ currentUser, authToken, isAuthenticated, loading, login, register, resetPassword, logout }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
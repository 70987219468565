import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useAuth } from '../../../AuthContext';

const ProfilePage = () => {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    User Details
                </Typography>
                <Typography variant="body1"><strong>Name:</strong> {currentUser.displayName || 'N/A'}</Typography>
                <Typography variant="body1"><strong>Email:</strong> {currentUser.email}</Typography>
                <Typography variant="body1"><strong>User Role:</strong> {'User'}</Typography> {/* Anpassungen hier nach Bedarf */}
                <Typography variant="body1"><strong>Freigegeben:</strong> {'Yes'}</Typography> {/* Anpassungen hier nach Bedarf */}
            </Paper>
        </Box>
    );
};

export default ProfilePage;
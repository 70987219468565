// src/components/briefing/BriefingComponent.js
import React, { useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import BriefingPreview from './BriefingPreview';
import BriefingEditor from './BriefingEditor';

const BriefingComponent = () => {
    const [briefingData, setBriefingData] = useState({
        briefingName: `New briefing ${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
        strategy: '',
        marketReadiness: [],
        focus: '',
        keywords: '',
        negativeKeywords: '',
        relevantCompanies: '',
        irrelevantSolutions: '',
        problemChallenge: '',
        applicationAreas: '',
        technologies: '',
    });
    const [activeStep, setActiveStep] = useState(0); // Starten beim ersten Schritt

    return (
        <Box sx={{ padding: '16px' }}>
            <TextField
                fullWidth
                label="Briefing Name"
                value={briefingData.briefingName}
                onChange={(e) => setBriefingData({ ...briefingData, briefingName: e.target.value })}
                sx={{ marginBottom: '16px' }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <BriefingPreview briefingData={briefingData} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BriefingEditor
                        briefingData={briefingData}
                        setBriefingData={setBriefingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BriefingComponent;
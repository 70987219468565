import React, { useState } from 'react';
import { Box, Chip, Button, Tooltip } from '@mui/material';

const TagCloud = ({ tags }) => {
    const [showMore, setShowMore] = useState(false);
    const displayedTags = showMore ? tags : tags.slice(0, 5);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <Box display="flex" flexWrap="wrap" gap={1}>
            {displayedTags.map((tag) => (
                <Tooltip key={tag.id} title={tag.category_name}>
                    <Chip label={tag.category_name.length > 12 ? `${tag.category_name.substring(0, 12)}...` : tag.category_name} size="small" />
                </Tooltip>
            ))}
            {tags.length > 5 && (
                <Button size="small" onClick={handleShowMore}>
                    {showMore ? 'Weniger' : 'Mehr'}
                </Button>
            )}
        </Box>
    );
};

export default TagCloud;

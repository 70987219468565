// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Login from './components/public/Login';
import Register from './components/public/Register';
import ResetPasswordRequest from './components/public/ResetPasswordRequest';
import CompanyOverviewLayout from './components/private/company/CompanyOverviewLayout';
import CompanyProfile from './components/private/company/CompanyProfile';
import ProfilePage from './components/private/profile/ProfilePage';
import MainLayout from './components/private/MainLayout';
import AlertProvider from './components/Alert';
import CompanySearchResults from "./components/private/company/CompanySearchResults";
import { createTheme, ThemeProvider } from '@mui/material';
import ProtectedRoute from "./components/private/ProtectedRoute";
import BriefingOverview from './components/private/briefing/BriefingOverview';
import BriefingComponent from "./components/private/briefing/BriefingComponent";

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    margin: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                },
                body: {
                    margin: 0,
                    padding: 0,
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                },
                icon: {
                    fontSize: '16px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    textTransform: 'none',
                    marginTop: '2px',
                    marginBottom: '2px',
                    padding: '4px',
                    boxShadow: 'none',
                    borderColor: '#183D61',
                    borderWidth: '1.5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(17, 42, 67, 0.5)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(17, 42, 67, 0.5)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#183D61',
                    },
                },
                input: {
                    fontSize: '16px',
                    '&::placeholder': {
                        color: 'rgba(17, 42, 67, 0.5)',
                        fontSize: '16px',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'rgba(17, 42, 67, 0.5)',
                    '&.Mui-checked': {
                        color: '#00A4A4',
                    },
                    '&.MuiCheckbox-indeterminate': {
                        color: '#00A4A4',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                checked: {
                    '& .MuiSvgIcon-root': {
                        color: '#183D61',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    textTransform: 'none',
                    marginTop: '2px',
                    marginBottom: '2px',
                    padding: '12px',
                    boxShadow: 'none',
                    borderColor: '#183D61',
                    borderWidth: '1.5px',
                },
                label: {
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: 500,
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#183D61',
            light: '#386FA6',
            dark: '#112A43',
            contrastText: '#fff',
        },
        secondary: {
            main: '#21D1D1',
            light: '#A6F5F5',
            dark: '#00A4A4',
            contrastText: '#fff',
        },
        grey: {
            50: '#FDFEFF',
            100: '#F4F7FC',
            200: '#E3E9F1',
            300: '#C1C8D2',
            400: '#97A2B1',
            500: '#6C7888',
            600: '#3C4654',
        },
        background: {
            paper: '#FDFEFF',
            default: '#F7F9FC',
        }
    },
    typography: {
        fontFamily: "Rubik",
        button: {
            fontFamily: "Rubik"
        },
        h1: {
            fontFamily: "Rubik"
        },
        h2: {
            fontFamily: "Rubik"
        },
        h3: {
            fontFamily: "Rubik"
        },
        h4: {
            fontFamily: "Rubik"
        },
        h5: {
            fontFamily: "Rubik"
        },
        h6: {
            fontFamily: "Rubik"
        },
        body1: {
            fontFamily: "Rubik"
        },
        body2: {
            fontFamily: "Rubik"
        },
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <AuthProvider>
                    <AlertProvider>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/reset-password" element={<ResetPasswordRequest />} />
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanyOverviewLayout />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/companies"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanyOverviewLayout />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/company/:slug"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanyProfile />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/profile"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <ProfilePage />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/search-results"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanySearchResults />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/briefings"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <BriefingOverview />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/briefing/:id"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <BriefingComponent />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </AlertProvider>
                </AuthProvider>
            </Router>
        </ThemeProvider>
    );
}

export default App;
import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Avatar, Button, Link, Paper, Divider } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SimilarCompanyCard from './SimilarCompanyCard';
import axiosInstance from "../../../axiosInstance";
// Import the PNG images from the correct path
import MoonshotLabel from '../../../images/Moonshot_Label.png';
import DTXLabel from '../../../images/DTX_Label.png';

const CompanyProfile = () => {
    const { slug } = useParams();
    const [company, setCompany] = useState(null);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/proxy/api:Y3T-Nl6Y/get_company_from_slug?Slug=${slug}`);
                const companyData = response.data;
                setCompany({
                    id: companyData.id || 'Unbekannt',
                    Company_name: companyData.Company_name || 'N/A',
                    Logo_Image: companyData.Logo_Image || { url: '' },
                    Headquarters_Location: companyData.Headquarters_Location || 'Vienna', // Set default location for example
                    Is_Moonshot: companyData.Is_Moonshot || false,
                    Is_Dtx: companyData.Is_Dtx || false,
                    URL_Text: companyData.URL_Text || 'N/A',
                    Company_description: companyData.Company_description || 'Keine Beschreibung verfügbar',
                    Founded_Date: companyData.Founded_Date || 'Unbekannt',
                    _company_similar_of_company_basic: companyData._company_similar_of_company_basic || []
                });
            } catch (error) {
                console.error('Error fetching company data:', error);
            }
        };

        fetchCompany();
    }, [slug]);

    if (!company) {
        return <Typography>Loading...</Typography>;
    }

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <Box sx={{ backgroundColor: '#F7F9FC', minHeight: '100vh' }}>
            <Box sx={{ width: '100%', backgroundColor: '#112A43', position: 'relative', paddingBottom: '24px' }}>
                <Box sx={{ position: 'absolute', bottom: '4px', left: 0, right: 0, height: '45px', backgroundColor: '#011529' }} />
                <Box sx={{ maxWidth: '1024px', margin: '0 auto', padding: '0 16px', color: '#ffffff', position: 'relative' }}>
                    <Link component={RouterLink} to="/" sx={{ display: 'block', mb: 2, color: '#90C0F0', fontFamily: 'Manrope', fontWeight: 'bold' }}>
                        &lt; Zurück
                    </Link>
                    <Box display="flex" alignItems="center" mb={2} sx={{ position: 'relative', zIndex: 1 }}>
                        <Avatar src={company.Logo_Image.url} alt={company.Company_name} sx={{ width: 128, height: 128, mr: 3, borderRadius: 2 }} />
                        <Box>
                            <Typography variant="h4" gutterBottom>{company.Company_name}</Typography>
                            <Box display="flex" alignItems="center" mb={1}>
                                <LocationOnIcon sx={{ mr: 1 }} />
                                <Typography variant="body1" sx={{ mr: 2 }}>{company.Headquarters_Location}</Typography>
                                <Box component="img" src={MoonshotLabel} alt="Moonshot" sx={{ height: 24, mr: 1 }} />
                                <Box component="img" src={DTXLabel} alt="DTX" sx={{ height: 24 }} />
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Link href={company.URL_Text.startsWith('http') ? company.URL_Text : `https://${company.URL_Text}`} target="_blank" rel="noopener noreferrer" sx={{ color: '#90C0F0', textDecoration: 'none', backgroundColor: '#011529', padding: '4px 8px', borderRadius: '4px', mr: 2 }}>
                                    {company.URL_Text}
                                </Link>
                                {company._company_diseasecategories_of_company_basi?.map(category => (
                                    <Typography key={category.id} variant="body2" sx={{ backgroundColor: '#011529', color: '#ffffff', borderRadius: '4px', padding: '4px 8px', mr: 1 }}>
                                        {category.Category_name}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ padding: 3, maxWidth: '1024px', margin: '0 auto' }}>
                <Paper sx={{ padding: 3, mb: 3, backgroundColor: '#ffffff', border: '0.5px solid #DCDCDC', borderRadius: '10px', boxShadow: 'none' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: '#386FA6' }}>Information</Typography>
                    <Divider sx={{ borderColor: '#DCDCDC', marginBottom: 2 }} />
                    <Typography variant="subtitle1" gutterBottom>Description</Typography>
                    <Typography variant="body1" gutterBottom>{company.Company_description}</Typography>
                    {company.Founded_Date && (
                        <Typography variant="body1"><strong>Founding year:</strong> {company.Founded_Date}</Typography>
                    )}
                </Paper>
                <Paper sx={{ padding: 3, mb: 3, backgroundColor: '#ffffff', border: '0.5px solid #DCDCDC', borderRadius: '10px', boxShadow: 'none' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: '#386FA6' }}>Technology</Typography>
                    <Divider sx={{ borderColor: '#DCDCDC', marginBottom: 2 }} />
                    {/* Add technology content here */}
                </Paper>
                <Paper sx={{ padding: 3, mb: 3, backgroundColor: '#ffffff', border: '0.5px solid #DCDCDC', borderRadius: '10px', boxShadow: 'none' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: '#386FA6' }}>Similar Companies</Typography>
                    <Divider sx={{ borderColor: '#DCDCDC', marginBottom: 2 }} />
                    <Box display="flex" flexWrap="wrap" gap={2}>
                        {company._company_similar_of_company_basic.length === 0 && (
                            <SimilarCompanyCard similar={{
                                Company_name: "Dummy Company",
                                Logo_Image: { url: "" },
                                Is_Moonshot: true,
                                Is_Dtx: true,
                                Similarity_value: 90,
                                Slug: "dummy-company",
                                _company_diseasecategories_of_company_basi: [
                                    { id: 1, Category_name: "Respiratory Diseases" },
                                    { id: 2, Category_name: "Asthma" }
                                ]
                            }} />
                        )}
                        {company._company_similar_of_company_basic.slice(0, showMore ? company._company_similar_of_company_basic.length : 3).map(similarCompany => (
                            <SimilarCompanyCard key={similarCompany.id} similar={similarCompany} />
                        ))}
                    </Box>
                    {company._company_similar_of_company_basic.length > 3 && (
                        <Button onClick={handleShowMore} variant="contained" sx={{ mt: 2 }}>
                            {showMore ? 'Show less' : `Show more (${company._company_similar_of_company_basic.length - 3})`}
                        </Button>
                    )}
                </Paper>
                <Paper sx={{ padding: 3, mb: 3, backgroundColor: '#ffffff', border: '0.5px solid #DCDCDC', borderRadius: '10px', boxShadow: 'none' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: '#386FA6' }}>Evaluation</Typography>
                    <Divider sx={{ borderColor: '#DCDCDC', marginBottom: 2 }} />
                    {/* Add evaluation content here */}
                </Paper>
                <Paper sx={{ padding: 3, backgroundColor: '#ffffff', border: '0.5px solid #DCDCDC', borderRadius: '10px', boxShadow: 'none' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: '#386FA6' }}>Funding</Typography>
                    <Divider sx={{ borderColor: '#DCDCDC', marginBottom: 2 }} />
                    <Typography variant="body1"><strong>Current funding:</strong> 2.3 million €</Typography>
                    <Typography variant="body1"><strong>Phase:</strong> Seed</Typography>
                </Paper>
                <Typography variant="body2" sx={{ position: 'absolute', bottom: 0, right: 0, padding: 1 }}>
                    ID: {company.id}
                </Typography>
            </Box>
        </Box>
    );
};

export default CompanyProfile;

import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

const BriefingOverview = () => {
    const navigate = useNavigate();
    const [briefings, setBriefings] = useState([]);

    useEffect(() => {
        const fetchBriefings = async () => {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/briefings/`);
                const sortedBriefings = response.data.sort((a, b) => {
                    const dateA = new Date(a.lastUpdatedAt || a.createdAt);
                    const dateB = new Date(b.lastUpdatedAt || b.createdAt);
                    return dateB - dateA;
                });
                setBriefings(sortedBriefings);
            } catch (error) {
                console.error('Error fetching briefings', error);
            }
        };

        fetchBriefings();
    }, []);

    const handleCreateNew = () => {
        navigate('/briefing/new');
    };

    const handleEditBriefing = (briefingId) => {
        navigate(`/briefing/${briefingId}`);
    };

    return (
        <Box sx={{ padding: '16px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">My Briefs</Typography>
                    <Button variant="contained" color="primary" onClick={handleCreateNew} sx={{ marginTop: '16px' }}>
                        + Create new
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Active briefs</Typography>
                    <Grid container spacing={2}>
                        {briefings.map((briefing) => (
                            <Grid item xs={12} md={6} key={briefing.id}>
                                <Box sx={{ padding: '16px', backgroundColor: 'primary.main', color: 'white', borderRadius: '8px' }}>
                                    <Typography variant="body1">{briefing.name}</Typography>
                                    <Typography variant="body2">Created on {new Date(briefing.createdAt).toLocaleDateString()}</Typography>
                                    <Typography variant="body2">Updated on {briefing.lastUpdatedAt ? new Date(briefing.lastUpdatedAt).toLocaleDateString() : 'N/A'}</Typography>
                                    <Typography variant="body2">Created by: {briefing.createdBy.email}</Typography>
                                    <Typography variant="body2">Status: {briefing.status}</Typography>
                                    <Button variant="contained" color="secondary" onClick={() => handleEditBriefing(briefing.id)} sx={{ marginTop: '8px' }}>
                                        {briefing.status === 'IN_PROGRESS' ? 'In Progress' : 'View Briefing'}
                                    </Button>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BriefingOverview;
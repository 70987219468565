// src/components/private/company/AddCompany.js
import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useAlert } from '../../Alert';
import { useAuth } from '../../../AuthContext'; // Richtig importieren
import axiosInstance from '../../../axiosInstance';

const AddCompany = () => {
    const [open, setOpen] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [url, setUrl] = useState('');
    const showAlert = useAlert();
    const { currentUser } = useAuth(); // useAuth verwenden

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSave = async () => {
        if (!currentUser) {
            showAlert('User not authenticated', 'error');
            return;
        }

        const slug = companyName.toLowerCase().replace(/ /g, '-');
        const companyNameLowercase = companyName.toLowerCase();

        const newCompany = {
            companyName,
            URL_Text: url,
            Slug: slug,
            Company_name_lowercase: companyNameLowercase,
        };

        try {
            const companyResponse = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/proxy/api:Y3T-Nl6Y/company_basic`, newCompany);
            const createdCompany = companyResponse.data;
            const createdCompanyId = createdCompany.id;

            const evaluationData = {
                company_basic_id: createdCompanyId,
                Requested_DTX: true,
                Requested_moonshot: true,
                user_id: currentUser.uid, // Assuming currentUser contains the user ID
            };

            await axiosInstance.post(`${process.env.REACT_APP_API_URL}/proxy/api:Y3T-Nl6Y/evaluation`, evaluationData);
            showAlert('Company added successfully', 'success');
            handleClose();
        } catch (error) {
            console.error('Error adding company', error);
            showAlert('Failed to add company', 'error');
        }
    };

    return (
        <Box>
            <Button onClick={handleOpen}>Add Company</Button>
            {open && (
                <Box>
                    <TextField
                        label="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <TextField
                        label="URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <Button onClick={handleSave}>Save</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </Box>
            )}
        </Box>
    );
};

export default AddCompany;
import React, { useState } from 'react';
import { TableCell, Box, Avatar, Typography, Button, Link as MuiLink, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import TagCloud from './TagCloud';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';

const CompanyItem = ({ company, userRole, onDelete }) => {
    const [showMoreDescription, setShowMoreDescription] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const similarCompanies = company._company_similar_of_company_basic || [];
    const logoUrl = company.Logo_Image?.url || '';
    const categories = company._company_diseasecategories_of_company_basi || [];
    const subcategories = categories.filter(category => !category.iscategory);

    const handleShowMoreDescription = () => {
        setShowMoreDescription(!showMoreDescription);
    };

    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        onDelete(company.id, company.Company_name);
        setOpenDialog(false);
    };

    const formatURL = (url) => {
        return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    };

    return (
        <>
            <TableCell>
                <Box display="flex" alignItems="center">
                    {logoUrl && <Avatar src={logoUrl} alt={company.Company_name} sx={{ marginRight: 2 }} />}
                    <MuiLink component={Link} to={`/company/${company.Slug}`} style={{ textDecoration: 'none' }}>
                        <Typography variant="body1" style={{ fontSize: '0.9rem', marginRight: 2 }}>{company.Company_name}</Typography>
                    </MuiLink>
                    <Tooltip title="Firmenhomepage öffnen">
                        <MuiLink href={formatURL(company.URL_Text)} target="_blank" rel="noopener">
                            <OpenInNewIcon fontSize="small" />
                        </MuiLink>
                    </Tooltip>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                    {company.Is_Moonshot && <Chip label="Moonshot" color="primary" sx={{ mr: 1 }} />}
                    {company.Is_Dtx && <Chip label="DTX" color="secondary" />}
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                    {showMoreDescription ? company.Company_description : `${company.Company_description.substring(0, 100)}...`}
                    <Button size="small" onClick={handleShowMoreDescription}>
                        {showMoreDescription ? 'Weniger' : 'Mehr'}
                    </Button>
                </Typography>

            </TableCell>
            <TableCell><Typography variant="body2" style={{ fontSize: '0.8rem' }}>{new Date(company.created_at).toLocaleDateString()}</Typography></TableCell>
            <TableCell style={{ maxWidth: 150 }}>
                <TagCloud tags={categories.filter(category => category.iscategory)} />
            </TableCell>
            <TableCell style={{ maxWidth: 150 }}>
                <TagCloud tags={subcategories} />
            </TableCell>
            <TableCell>
                <Box display="flex" flexDirection="column">
                    {similarCompanies.length > 0 ? similarCompanies.map((similar) => (
                        <Box key={similar.id} display="flex" alignItems="center">
                            <Avatar src={similar.Logo_Image?.url || ''} alt={similar.similar_to_company_name} sx={{ width: 24, height: 24, marginRight: 1 }} />
                            <MuiLink component={Link} to={`/company/${similar.Slug}`} style={{ textDecoration: 'none' }}>
                                <Typography variant="body2" style={{ fontSize: '0.8rem' }}>{similar.similar_to_company_name} <span style={{ fontWeight: 'bold' }}>{similar.Similarity_value.toFixed(2)}</span></Typography>
                            </MuiLink>
                        </Box>
                    )) : 'Keine ähnlichen Firmen'}
                </Box>
            </TableCell>
            {userRole === 'Super_Admin' && (
                <TableCell>
                    <IconButton color="secondary" onClick={handleDeleteClick}>
                        <DeleteIcon />
                    </IconButton>
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Bestätigen</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Möchten Sie die Firma "{company.Company_name}" wirklich löschen?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={handleConfirmDelete} color="secondary">
                                Löschen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </TableCell>
            )}
        </>
    );
};

export default CompanyItem;
